<template>
  <div class="g11-tender-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import g11Service from '@/services/g11Service'
import auth from '@/common/auth'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'

export default {
  name: 'G11TenderStat',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      spreadInfo: {
        sheetName: '工程进度月计划汇总表',
        columns: [
          {
            label: '序号',
            dataKey: 'index',
            width: '50'
          },
          {
            label: '清单项目',
            dataKey: 'meterageDisplayCode',
            width: 200,
            autoMerge: true
          },
          {
            label: '工程或费用项目名称',
            dataKey: 'meterageName',
            width: 200
          },
          {
            label: '单位',
            dataKey: 'unit',
            width: 90
          },
          {
            label: '合同数量',
            children: [
              {
                label: '原合同数量',
                dataKey: 'num',
                width: 90
              },
              {
                label: '按施工图调整后数量',
                dataKey: 'num',
                width: 90
              },
              {
                label: '变更数量',
                width: 90
              },
              {
                label: '变更后数量',
                dataKey: 'num',
                width: 90
              },
              {
                label: '工作量（元）',
                dataKey: 'amount',
                width: 90
              }
            ]
          },
          {
            label: '本年计划',
            children: [
              {
                label: '工程量',
                dataKey: 'currentYearNum'
              },
              {
                label: '工作量（元）',
                dataKey: 'currentYearAmount'
              },
              {
                label: '占合同数量%',
                dataKey: 'currentYearPercent'
              }
            ]
          },
          {
            label: '本月计划',
            children: [
              {
                label: '工程量',
                dataKey: 'currentMonthNum'
              },
              {
                label: '工作量（元）',
                dataKey: 'currentMonthAmount'
              },
              {
                label: '占年工程量%',
                dataKey: 'currentMonthPercent'
              }
            ]
          },
          {
            label: '本月计划年度累计',
            children: [
              {
                label: '工程量',
                dataKey: 'totalMonthNum'
              },
              {
                label: '工作量（元）',
                dataKey: 'totalMonthAmount'
              },
              {
                label: '占年工程量（%）',
                dataKey: 'totalMonthPercent'
              }
            ]
          }
        ]
      },
      defaultDataList: [],
      engineeringList: [],
      tenderStatList: [],
      engineeringMap: {},
      otherTenderStats: [],
      reportDate: ''
    }
  },
  watch: {
    reportDate (newVal) {
      this.getData()
    }
  },
  methods: {
    handleExport () {
      this.$refs.mySpread.exportExcel('标段工程进度月计划汇总表.xlsx')
    },
    generateMapData () {
      this.defaultDataList = this.tenderStatList.map((item, index) => {
        item.index = index
        return item
      })

      // 合并单元格
      this.$nextTick(() => {
        const rowIndexArray = [0, 1, 2, 3, 4, 5, 6, 7]
        for (let i = 0; i < rowIndexArray.length; ++i) {
          const rowIndex = rowIndexArray[i]
          this.$refs.mySpread.worksheet.addSpan(rowIndex, 2, 1, 2)
        }
      })

      this.$loading().close()
    },
    getData () {
      this.$myLoading()
      this.tenderStatList = []
      this.defaultDataList = []
      if (!this.reportDate) {
        this.$loading().close()
        return
      }
      const year = parseInt(this.reportDate.substr(0, 4))
      const month = parseInt(this.reportDate.substr(4, 2))
      const engineeringType = this.$route.query.engineeringType || '土建'
      g11Service.getTenderStat(year, month, auth.getUserInfo().projectGuid, engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.tenderStatList = res.data.data
            console.log(this.tenderStatList)

            this.generateMapData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.g11-tender-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
